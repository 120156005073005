@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Regular.eot');
  src: local('☺'), url('./Roboto-Regular.woff') format('woff'),
    url('./Roboto-Regular.ttf') format('truetype'),
    url('./Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Light.eot');
  src: local('☺'), url('./Roboto-Light.woff') format('woff'),
    url('./Roboto-Light.ttf') format('truetype'),
    url('./Roboto-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Medium.eot');
  src: local('☺'), url('./Roboto-Medium.woff') format('woff'),
    url('./Roboto-Medium.ttf') format('truetype'),
    url('./Roboto-Medium.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-SemiBold.eot');
  src: local('☺'), url('./Montserrat-SemiBold.woff') format('woff'),
    url('./Montserrat-SemiBold.ttf') format('truetype'),
    url('./Montserrat-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
